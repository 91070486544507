// IE9 does not support Element.classList.toggle.
const toggleClass = (element, className) => {
  element.className = // eslint-disable-line no-param-reassign
    element.className.indexOf(className) === -1
      ? `${element.className} ${className}`
      : element.className.replace(` ${className}`, '');
};

const setupFlyoutHandlers = (querySelectorAll) => {
  let flyoutIsOpen = false;

  const flyout = querySelectorAll('#sp-nav-flyout')[0];
  const allItemDesktop = querySelectorAll('#sp-nav-all-item')[0];
  const allButtonDesktop = querySelectorAll('#sp-nav-all-sport-button')[0];
  const allButtonMobile = querySelectorAll('#sp-nav-all-sport-button-mobile')[0];

  const toggleFlyout = (statName) => {
    document.dispatchEvent(
      new CustomEvent('bbc-user-event', {
        detail: {
          container: 'SportNavigation',
          label: statName,
          type: 'click',
          isClick: 'true',
          source: 'sport',
          result: window.location.href,
          metadata: {
            navLinkTitle: `All Sport ${flyoutIsOpen ? 'Close' : 'Open'}`,
            navLink: '',
          },
        },
      }),
    );

    flyoutIsOpen = !flyoutIsOpen;
    toggleClass(flyout, 'sp-c-sport-flyout--expanded');
    toggleClass(allItemDesktop, 'sp-c-sport-navigation__item--all-selected');
    toggleClass(allButtonMobile, 'sp-c-global-header__all-sports--selected');
  };

  if (allButtonDesktop) {
    allButtonDesktop.addEventListener('click', () => toggleFlyout('primary-nav-v2'));
  }

  if (allButtonMobile) {
    allButtonMobile.addEventListener('click', () => toggleFlyout('primary-nav-v2-mobile'));
  }
};

const setupItemClickStats = (querySelectorAll) => {
  querySelectorAll('.sp-nav-click-stat').forEach((element) => {
    element.addEventListener('click', () => {
      document.dispatchEvent(
        new CustomEvent('bbc-user-event', {
          detail: {
            container: 'SportNavigation',
            label: element.getAttribute('data-stat-name'),
            type: 'click',
            isClick: 'true',
            source: 'sport',
            result: window.location.href,
            metadata: {
              navLinkTitle: element.getAttribute('data-stat-title'),
              navLink: element.getAttribute('data-stat-link'),
            },
          },
        }),
      );
    });
  });
};

const bootstrap = (querySelectorAll) => {
  setupFlyoutHandlers(querySelectorAll);
  setupItemClickStats(querySelectorAll);
};

export default bootstrap;
